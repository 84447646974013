import React from 'react';
import './skills.styles.scss';

const Skills = () => {
    return(
        <div className="skills">
        </div>
    )

}

export default Skills;